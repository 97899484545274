import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Img from "gatsby-image"

import Layout from '../../layouts'

const PageContact = ({ data }) => {
  const page = data.page
  return (
  <Layout>
    <Helmet>
      <title>Contact - Sureventure Camperbouw</title>
      <meta name="description" content={page.metaDescription} />
    </Helmet>
    <div className="panel panel--large row">
      <div className="content-text medium-12 columns">
        <h1 className="contact-title">{page.title}</h1>
      </div>
    </div>
    <div className="panel panel--large">
      <div className="row">
        <div className="small-12 contact-content content medium-6 large-4 columns content-text">
          {page.intro &&
            <div className="intro">{page.intro}</div>
          }
          <address className="contact-address">
            {page.address &&
              <div className="intro">
                {page.address.street && page.address.street} {page.address.housenumber && page.address.housenumber}<br />
                {page.address.postalcode && page.address.postalcode}<br />
                {page.address.city && page.address.city} {page.address.province && `(${page.address.province})`}<br />
                {page.address.country && page.address.country}
              </div>
            }
            <p>
              {/* <a className="link-hidden" href="tel:+31644628106">06 44 628 106</a><br /> */}
              <a className="link-hidden" href="mailto:info@surventure.nl">info@surventure.nl</a>
            </p>
            <p>
              <a href="https://goo.gl/maps/s3vMdv3nLDE2" target="_blank" rel="noopener noreferrer">Bekijk op Google Maps</a>
            </p>
          </address>
          <p>
            <a href="/downloads/Algemene-Voorwaarden-Surventure-Camperbouw.pdf" target="_blank">Download onze algemene voorwaarden</a>
          </p>
        </div>
        <div className="small-12 medium-6 large-8 columns content-img">
          {page.image &&
            <Img fluid={page.image.asset.fluid} alt="Werkplaats" />
          }
        </div>
      </div>
    </div>
  </Layout>
)}

export default PageContact

export const query = graphql`
  query {
    page: sanityPageContact {
      title
      metaDescription
      intro
      address {
        company
        street
        housenumber
        postalcode
        city
        province
        country
      }
      image {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
    }
  }
`

